import React from "react";
import {PageMetier} from 'amg-it-theme'; 

let techniquesPageData ={
  header: {
    title: "techniques",
    path: "accueil / nos métiers / techniques"
  },
  content:{
    category: "nos métiers",
    title: "TECHNIQUES",
    subtitle: "Proin sem nulla, vestibulum in ornare ac, sodales non magna. Cras condimentum turpis sed vestibulum aliquet quisque, magna eget vestibulum tristique, risus purus rhoncus",
    text: "Aliquam lectus lorem, tristique in diam vel, placerat sagittis est. Aliquam tincidunt nulla non diam interdum pulvinar. Sed id purus nisl. Praesent pharetra ut elit nec ornare. Nam quis turpis in arcu venenatis molestie eget quis elit. Nullam consectetur neque ornare enim aliquet, vel lobortis purus consectetur. Mauris viverra ut ex non varius. Cras mollis turpis mauris, eu faucibus leo elementum vel. Integer gravida vel est id commodo. Donec vestibulum nibh quis velit tincidunt lacinia. "
  },
  fiches: [
    {
      title: "Administrateur Système Réseau",
      text: "Maintenir opérationnelles, les infrastructures système et / ou réseau, superviser le fonctionnement des infrastructures et assurer leur sécurité, mettre en œuvre les évolutions de service et appliquer les procédures d’exploitation et de maintenance.",
      link: "/metiers/techniques/admin_system"
    },
    {
      title: "Développeur",
      text: "Aliquam lectus lorem, tristique in diam vel, placerat sagittis est. Aliquam tincidunt nulla non diam interdum pulvinar. Sed id purus nisl. Praesent pharetra ut elit nec ornare. Nam quis turpis in arcu venenatis molestie eget quis elit. Nullam consectetur neque ornare enim aliquet, vel lobortis purus consectetur.",
      link: "/metiers/techniques/developpeur"
    }

  ]
}


const LocalPage = () => (
  <PageMetier {...techniquesPageData}/>
);

export default LocalPage;